class User {
	constructor({ _id, name, age, email }) {
		this.id = _id;
		this.name = name;
		this.age = age;
		this.email = email;
	}
}

export default User;
